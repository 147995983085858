import vkLogo from "../assets/img/vkLogo.png";
import telegramLogo from "../assets/img/telegramLogo.png";

const Footer = () => {
    return (
        <div className="footer">
            {`Created by Georgii Imeshkenov, ${(new Date()).getFullYear()}`}
            <a href="https://vk.com/veritaris" target="_blank" rel="noreferrer"><img src={vkLogo} alt="My vk.com profile"/></a>
            <a href="tg://resolve?domain=Veritaris"><img src={telegramLogo} alt="My Telegram profile"/></a>
        </div>
    );
}

export default Footer;