import { useParams } from "react-router-dom";

interface RouterParams {
    appName: string;
}

const AppContainer = () => {
    const { appName } = useParams<RouterParams>();
    return (
        <div>
            <h3>App is: {appName}</h3>
        </div>
    );
}

export default AppContainer;