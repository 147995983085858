import React from "react";

interface Props {
    cards?: JSX.Element[],
    maxPerRow?: bigint,
}

const CardsContainer: React.FC<Props> = ({cards, maxPerRow}) => {
    return (
        <div className="cards-container">
            {cards}
        </div>
    );
}

export default CardsContainer;
