import React from "react";


interface Props {
    text: string
    isLast?: boolean
}

const TerminalNewlinePrompt: React.FC<Props> = ({text, isLast = false}) => {
    return (
        <div className={"noscript-continue-prompt-row noscript-prompt-row" + (isLast ? "" : " noscript-line-with-backslash")}>
            <span className="noscript-input-row">{text}&nbsp;</span>
        </div>
    );
}

export default TerminalNewlinePrompt;