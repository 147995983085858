import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import React from "react";
import MainRouter from "./routes/MainRouter";
import Header from "components/Header";
import Footer from "components/Footer";
import store from "./store/store";
import "./styles/index";

const App: React.FC = () => {
    return (
        <Provider store={store}>
            <BrowserRouter>
                <Header/>
                <MainRouter/>
                <Footer/>
            </BrowserRouter>
        </Provider>
    );
}

export default App;
