import SimpleCard from "../components/SimpleCard";
import connection from "../assets/img/connection.png";
import CardsContainer from "../components/CardsContainer";
import RouteEnums from "routes/RouteEnums";

const MainPageContainer = () => {
    const cards: JSX.Element[] | undefined = [
        <SimpleCard
            title="About me"
            description="Some words about my life and interests"
            link={RouteEnums.ABOUT}
            key="aboutme"
        />,
        <SimpleCard
            title="Experience"
            description="My work and study experience and achievements"
            link={RouteEnums.EXPERIENCE}
            img={<img src={connection} alt="Science"/>}
            key="experience"
        />,
    ];
    return (
        <div className="main-page-container">
            <CardsContainer cards={cards}/>
        </div>
    );
}

export default MainPageContainer;
