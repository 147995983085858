import React from "react";


interface Props {
    command: string
}

const TerminalLineWithPrompt: React.FC<Props> = ({command}) => {
    return (
        <div className="noscript-prompt-row">
            <span className="noscript-prompt">georgiiimeshkenov@veritaris /home/veritaris/ $&nbsp;</span>
            <span className="noscript-input-row">{command}</span>
        </div>
    );
}

export default TerminalLineWithPrompt;