import React from "react";
import { Route, Switch } from "react-router-dom";
import AboutContainer from "../containers/AboutContainer";
import AppContainer from "../containers/AppContainer";
import AppsPageContainer from "../containers/AppsPageContainer";
import ExperienceContainer from "../containers/ExperienceContainer";
import GithubActivityContainer from "../containers/GithubActivityContainer";
import GlobalContainer from "../containers/GlobalContainer";
import MainPageContainer from "../containers/MainPageContainer";
import RouteEnums from "./RouteEnums";
import NoScriptPage from "../containers/NoScriptPage";

const PublicRoutes = () => {
    const route = <Switch>
        <Route exact path="/noscript" component={NoScriptPage}/>
        <Route exact path={RouteEnums.HOMEPAGE} component={MainPageContainer}/>
        <Route exact path={RouteEnums.EXPERIENCE} component={ExperienceContainer}/>
        <Route exact path={RouteEnums.ABOUT} component={AboutContainer}/>
        <Route exact path={RouteEnums.MYGITHUB} component={GithubActivityContainer}/>
        <Route exact path={RouteEnums.APPS} component={AppsPageContainer}/>
        <Route exact path={`${RouteEnums.APPS}/:appName`} component={AppContainer}/>
        <Route exact path={`${RouteEnums.HOMEPAGE}/:page`} component={MainPageContainer}/>
    </Switch>;
    return (
        <GlobalContainer innerComponent={route}/>
    );
}

export default PublicRoutes;
