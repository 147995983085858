import homepageLogo from "assets/img/home.png";
import user from "assets/img/user.png";
import experience from "assets/img/experience.svg";
import apps from "assets/img/apps.svg";
import RouteEnums from "routes/RouteEnums";
import HeaderLink from "./HeaderLink";
import React from "react";

const Header: React.FC = () => {
  return (
    <div className="header header-links">
      <div>
        <HeaderLink image={homepageLogo} alt='Home page' pageLink={RouteEnums.HOMEPAGE}/>
        <HeaderLink image={user} alt='About me' pageLink={RouteEnums.ABOUT}/>
        <HeaderLink image={experience} alt='My experience' pageLink={RouteEnums.EXPERIENCE}/>
        <HeaderLink image={apps} alt='My apps' pageLink={RouteEnums.APPS}/>
      </div>
    </div>
  );
}

export default Header;
