import React from "react";
interface Props {
  name: string,
  description: string,
  image?: string | JSX.Element,
  link?: string
}

const MyProjectPreview: React.FC<Props> = ({name, description, image, link}) => {
    return (
        <div className="my-project-preview-card">
            <div>
                <span>{name}</span>
                <span>{description}</span>
            </div>
        </div>
    );
}

export default MyProjectPreview;