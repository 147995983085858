import React from "react";

interface Props {
    apps?: JSX.Element[]
}

const AppsContainer: React.FC<Props> = ({apps}) => {
    return (
        <div className="apps-container">
            {apps}
        </div>
    );
}

export default AppsContainer;
