import React from "react";


interface Props {
    text?: string
    element?: JSX.Element
}

const TerminalLineAnswer: React.FC<Props> = ({text, element}) => {
    return (
        <div className="noscript-prompt-row">
            {
                element == null
                ?
                <span className="noscript-input-row">{text}</span>
                :
                element
            }
        </div>
    );
}

export default TerminalLineAnswer;