import React, {useState} from "react";
import chevron from "../assets/img/chevron.png";

interface Props {
  title: string,
  className?: string,
  hiddenComponent?: JSX.Element
}

const SlidedownSpoiler: React.FC<Props> = ({title, className, hiddenComponent}) => {
  const [isOpened, setIsOpened] = useState(false);

  const toggleSpoiler = (event: React.MouseEvent) => {
    setIsOpened(!isOpened);
  }
  
  return (
    <div className={"slidedown-spoiler"} onClick={toggleSpoiler}>
      <div className="slidedown-spoiler-toggle">
        <img
          src={chevron}
          alt=">"
          className={`spoiler-chevron ${isOpened ? "is-active" : ""}`}
        />
        <span>{title}</span>
      </div>
      <div
        className={`spoiler-content ${isOpened ? "is-active" : ""}`}>
        {hiddenComponent}
      </div>
    </div>
  );
}

export default SlidedownSpoiler;
