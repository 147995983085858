import React from "react";
import {Link} from "react-router-dom";
import store from "../store/store";
import {setTab} from "../reducers/tabReducers";

interface HeaderLinkProps {
  image: string,
  alt: string,
  pageLink: string,
}

const HeaderLink: React.FC<HeaderLinkProps> = ({image, alt, pageLink}) => {
  const tabName = pageLink.replaceAll("/", "");
  store.dispatch(setTab(tabName));
  return (
    <Link to={pageLink}>
      <img src={image} alt={alt} className='header-img-link'/>
    </Link>
  );
}

export default HeaderLink;