import React from "react";
import KeySkillGrade from "../enums/KeySkillGrades";

interface Props {
  keySkill: string,
  grade: KeySkillGrade
}

const KeySkill: React.FC<Props> = ({keySkill, grade}) => {
  return (
    <div className="key-skill">
      <span>{keySkill}</span>
      <div className="key-skill-grade" style={{backgroundColor: grade}}/>
    </div>
  );
}

export default KeySkill;