import React from "react";
import AppsContainer from "../components/AppsContainer";
import AppCard from "../components/AppCard";

const AppsPageContainer: React.FC = () => {
  const apps: JSX.Element[] | undefined = [
    <AppCard
      title="Emphasoft Test"
      description="My test case for Emphasoft company"
      link="/apps/est"
      appLink="https://est.veritaris.me/"
      isBuiltIn={false}
      previewImg={null}
    />,
    <AppCard
      title="Stonks calculator"
      description="Very simple calculator for your stonks"
      appLink="https://stonks.veritaris.me/"
      link="/apps/stonks"
      isBuiltIn={true}
      previewImg={null}
    />
  ];

  return (
    <div className="apps-page">
      <div className="apps-page-container">
        <span>This is all my projects. Some of them are my pet projects, the other one are test task for work or interview</span>
        <AppsContainer apps={apps}/>
      </div>
    </div>
  );
}

export default AppsPageContainer;
