import React from "react";
import {Link} from "react-router-dom";

const og = require("open-graph");

interface AppCardProps {
  title: string,
  description: string,
  link: string,
  isBuiltIn: boolean,
  previewImg: string | HTMLImageElement | null,
  appLink: string
}

const AppCard: React.FC<AppCardProps> = ({title, description, link, appLink, isBuiltIn, previewImg}) => {
  let data: string = "";
  
  const parseOG = () => {
    og(appLink, (err: any, meta: string) => {
      return meta;
    });
  }
  
  const imagePreview = (
    <div className="app-card-preview-image">
      {
        (typeof (previewImg) === "string") ? <img src={previewImg} alt={title}/> : previewImg
      }
    </div>
  )
  
  const ogPreview = (
    <div className="og-preview-card">
    
    </div>
  )
  return (
    <Link to={link} className="app-card-link">
      <div className="app-card">
        <div className="app-card-title">
          <span>{title}</span>
        </div>
        <div className="app-card-description">
          <span>{description}</span>
        </div>
        {previewImg === null ? imagePreview : ogPreview}
      </div>
    </Link>
  );
}

export default AppCard;