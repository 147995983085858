import React from "react";
import TerminalLineWithPrompt from "../components/TerminalLineWithPrompt";
import TerminalLineAnswer from "../components/TerminalLineAnswer";
import TerminalNewlinePrompt from "../components/TerminalNewlinePrompt";

const NoScriptPage = () => {
    return (
        <div>
            <div className="noscript-layout">
                <span>Welcome to my website</span> <br/>
                <span>Last login: today (now) on ttys001 from yourcomputer</span><br/><br/>

                <span>This is kinda of easter egg.</span><br/>

                <TerminalLineWithPrompt command={"javascript"}/>
                <span className="noscript-input-row">javascript: enabled</span>

                <TerminalLineWithPrompt command={"ls"}/>
                <div className="noscript-input-row">
                    <span className="noscript-executable">whoami </span>
                    <span className="noscript-executable">github </span>
                    <span className="noscript-executable">linkedin  </span>
                    <span>gpg_fingerprint </span>
                    <span className="noscript-executable">sendmeamessage </span>
                </div>

                <TerminalLineWithPrompt command={"whoami \\"}/>
                <TerminalNewlinePrompt text={"-d name=\"Georgii Imeshkenov\""}/>
                <TerminalNewlinePrompt text={"-d email=georgiiimeshkenov@gmail.com"}/>
                <TerminalNewlinePrompt text={"-d bachelor=\"ITMO University, low temperature physics\""}/>
                <TerminalNewlinePrompt text={"-d master=\"MIPT, particle physics and cosmology\""}/>
                <TerminalNewlinePrompt text={"-d profession=\"Python and Java developer\""}/>
                <TerminalNewlinePrompt text={"-d currentJob=\"Sr Data Engineer at MetaMap\""} isLast={true}/>

                <TerminalLineWithPrompt command={"cat gpg_fingerprint"}/>
                <TerminalLineAnswer text={"GPG fingerprint: 98C3 9503 6AC6 DF13 7B5C  75A9 5587 A8E3 4783 132E"}/>
                <TerminalLineAnswer element={
                    <span className="noscript-input-row">Public key url:&nbsp;
                        <a href="https://demo.web.veritaris.me/static/veritaris.pub" target="_blank" rel="noreferrer" className="noscript-link">
                            https://demo.web.veritaris.me/static/veritaris.pub
                        </a>
                    </span>
                }/>

                <TerminalLineWithPrompt command={"./github"}/>
                <TerminalLineAnswer element={
                    <span className="noscript-input-row">
                        <a href="https://github.com/veritaris" target="_blank" rel="noreferrer" className="noscript-link">
                            https://github.com/veritaris
                        </a>
                    </span>
                }/>

                <TerminalLineWithPrompt command={"./linkedin"}/>
                <TerminalLineAnswer element={
                    <span className="noscript-input-row">
                        <a href="https://linkedin.com/in/gimeshkenov/" target="_blank" rel="noreferrer" className="noscript-link">
                            https://linkedin.com/in/gimeshkenov/
                        </a>
                    </span>
                }/>

                <TerminalLineWithPrompt command={"./sendmeamessage \\"}/>
                <form action="https://demo.web.veritaris.me/noscriptmessage/" method="post" target="hiddenFrame">
                    <div className="noscript-prompt-row noscript-message-before noscript-message-ending-quote-with-newline">
                        <input id="message" name="message" type="text" className="noscript-input-row" placeholder="Some message..."/>
                    </div>
                    <div className="noscript-prompt-row noscript-message-from-before noscript-message-ending-quote">
                        <input id="from" name="from" type="text" className="noscript-input-row" placeholder="from..."/>
                    </div>
                    <input type="submit" hidden/>
                </form>
                <iframe title="hiddenFrame" name="hiddenFrame" className="hide" style={{display: "none"}}></iframe>
            </div>
        </div>
    );
}

export default NoScriptPage;