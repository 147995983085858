import SlidedownSpoiler from "../components/SlidedownSpoiler";
import KeySkill from "../components/KeySkill";
import KeySkillGrade from "../enums/KeySkillGrades";
import MyProjectPreview from "../components/MyProjectPreview";

const ExperienceContainer: React.FC = () => {
    const schoolProjects = (
        <div className="bullet-list">
            <ul>
                <li>
                    Automation process of creating virtual 3D model of given object with Arduino, 2015
                </li>
                <li>
                    Grant work with Open BCI in Orlyonok camp in Krasnodar, April 2016
                </li>
                <li>
                    Prizewinner in region's physics olympiad in 2016
                </li>
            </ul>
        </div>
    );

    const programmingCompetitions = (
        <div className="bullet-list">
            <ul>
                <li>
                    HackUniversity 2018 – machine learning for manufacture case
                </li>
                <li>
                    Digital Education Hack 2019 – Augment Reality case for building logical circuits on table using your phone
                </li>
            </ul>
        </div>
    );
    let isRankDisplayNeeded = window.screen.width > 600;
    const myGitHubStats = (
        <div className="my-github-stats">
            <img
                src={`https://github-readme-stats.vercel.app/api?username=veritaris&show_icons=true&theme=onedark&bg_color=282c34&title_color=0aa78d&text_color=f0f0f0&hide_border=true${isRankDisplayNeeded ? "" : "&hide_rank=true"}`}
                alt="My github stats"
            />
        </div>
    )

    return (
        <div className="experience-page-container">
            <div className="long-text-container">
                <h2>My experience</h2>
                <SlidedownSpoiler title="School projects & achievements" hiddenComponent={schoolProjects}/>
                <SlidedownSpoiler title="Hackatons & other programming competitions" hiddenComponent={programmingCompetitions}/>
                <SlidedownSpoiler title="My GitHub stats" hiddenComponent={myGitHubStats}/>
                <h2>My work</h2>
                <div>
                    Currently work in <a href="https://emphasoft.com/" target="_blank" rel="noreferrer"><span className={"emphasoft"}>Emphasoft</span></a> since summer 2020
                </div>
                <h2>
                    Key skills
                    <div className="tooltip">
                        ?
                        <div className="tooltip-text-background"/>
                        <div className="tooltip-text">
                            <span className="color-definition">Colors definition:</span><br/>
                            <span className="color-definition-circle" style={{backgroundColor: KeySkillGrade.NOT_STARTED}}/><span className="color-definition">Interested to learn, but not started yet</span><br/>
                            <span className="color-definition-circle" style={{backgroundColor: KeySkillGrade.JUST_STARTED}}/><span className="color-definition">Just started to learn it</span><br/>
                            <span className="color-definition-circle" style={{backgroundColor: KeySkillGrade.MEDIUM}}/><span className="color-definition">In a middle of learning, have 1-3 projects with this technology</span><br/>
                            <span className="color-definition-circle" style={{backgroundColor: KeySkillGrade.HIGH}}/><span className="color-definition">Have a very good experience of using it, made {">"}5 projects using this technology</span>
                        </div>
                    </div>
                </h2>
                <div className="key-skills-container">
                    <KeySkill grade={KeySkillGrade.HIGH} keySkill="git"/>
                    <KeySkill grade={KeySkillGrade.HIGH} keySkill="docker"/>
                    <KeySkill grade={KeySkillGrade.JUST_STARTED} keySkill="kubernetes"/>
                    <KeySkill grade={KeySkillGrade.MEDIUM} keySkill="helm"/>
                    <KeySkill grade={KeySkillGrade.HIGH} keySkill="linux"/>
                    <KeySkill grade={KeySkillGrade.HIGH} keySkill="Ubuntu"/>
                    <KeySkill grade={KeySkillGrade.HIGH} keySkill="Django"/>
                    <KeySkill grade={KeySkillGrade.HIGH} keySkill="flask"/>
                    <KeySkill grade={KeySkillGrade.MEDIUM} keySkill="React"/>
                    <KeySkill grade={KeySkillGrade.MEDIUM} keySkill="Redux"/>
                    <KeySkill grade={KeySkillGrade.MEDIUM} keySkill="fastapi"/>
                    <KeySkill grade={KeySkillGrade.HIGH} keySkill="website scraping"/>
                    <KeySkill grade={KeySkillGrade.HIGH} keySkill="async"/>
                    <KeySkill grade={KeySkillGrade.MEDIUM} keySkill="Nginx"/>
                    <KeySkill grade={KeySkillGrade.MEDIUM} keySkill="SQL"/>
                    <KeySkill grade={KeySkillGrade.HIGH} keySkill="PostgreSQL"/>
                    <KeySkill grade={KeySkillGrade.MEDIUM} keySkill="MySQL"/>
                    <KeySkill grade={KeySkillGrade.MEDIUM} keySkill="MongoDB"/>
                    <KeySkill grade={KeySkillGrade.MEDIUM} keySkill="ELK"/>
                    <KeySkill grade={KeySkillGrade.MEDIUM} keySkill="DevOps"/>
                    <KeySkill grade={KeySkillGrade.HIGH} keySkill="Java"/>
                    <KeySkill grade={KeySkillGrade.HIGH} keySkill="bash"/>
                </div>
                <h2>My web projects</h2>
                <div className="my-project-cards-container">
                    <MyProjectPreview
                        image={"datatagger.svg"}
                        name={"Data tagger"}
                        description={"Data tagging (labelling, markup) tool for datasets. Made with Java "}
                    />
                    <MyProjectPreview
                        image={"cloudrep.svg"}
                        name={"CloudRep"}
                        description={"Java artifacts repository with public & private artifacts 4 u"}
                    />
                    <MyProjectPreview
                        image={"stonks.svg"}
                        name={"Stonks"}
                        description={"Simple app to calculate value % increase (or decrease), revenue || losses"}
                    />
                    <MyProjectPreview
                        image={"EST.svg"}
                        name={"ELT"}
                        description={"My first success test case for work. Can be used if u have an account at vk.com social network"}
                    />
                </div>
                <h2>Others</h2>
                <ul>
                    <li>
                        System administrator, Java-developer at<span> </span>
                        <a
                            href="https://dreamfinity.org"
                            className="dreamfinity-link"
                            target="_blank"
                            rel="noreferrer">
                            Dreamfinity
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    );
}

export default ExperienceContainer;
