import {NavLink} from "react-router-dom";
import React from "react";

interface Props {
  title: string,
  description: string,
  img?: string | JSX.Element,
  link?: string
}

const SimpleCard: React.FC<Props> = ({title, description, img, link}) => {
  return (
    <NavLink to={link || "/"} target="_self" className="simple-card-navlink">
      <div className="simple-card">
        <div className="simple-card-text">
          <p className="simple-card-title">
            {title}
          </p>
          <p className="simple-card-description">
            {description}
          </p>
        </div>
        <div className="simple-card-image">
          {img}
        </div>
      </div>
    </NavLink>
  );
}

export default SimpleCard;
