import React from "react";

interface Props {
    innerComponent: JSX.Element
}

const GlobalContainer: React.FC<Props> = ({innerComponent}) => {
    return (
        <div className="global-container">
            {innerComponent}
        </div>
    );
}

export default GlobalContainer;
