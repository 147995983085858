import {createSlice} from "@reduxjs/toolkit";

const tabReducer = createSlice({
  name: "tabs",
  initialState: {
    tab: "main"
  },
  reducers: {
    setTab(state, action) {
      state.tab = action.payload;
    },
  }
})

export const {setTab,} = tabReducer.actions
export default tabReducer;